<template>
	<ValidationForm #default="{ handleSubmit }">
		<SSOConfigurationLayout
			:title="pageTitle"
			:caption="pageCaption"
		>
			<template #content>
				<SSOSetupDetailsCard
					:sso-configuration.sync="ssoConfiguration"
					:user-full-permissions="userFullPermissions"
					:lookup-data="lookupData"
				/>
				<SSOConfigurationCard
					v-if="ssoConfiguration.ssoProviderId"
					:sso-configuration.sync="ssoConfiguration"
					:user-full-permissions="userFullPermissions"
					:lookup-data="lookupData"
				/>
				<SSOMappingCard
					v-if="ssoConfiguration.ssoProviderId"
					:sso-configuration.sync="ssoConfiguration"
					:user-full-permissions="userFullPermissions"
					:lookup-data="lookupData"
					@show-mapping-modal="updateShowMappingModal"
					@delete-claim="setClaimToRemove"
				/>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton
							test-id="manage-sso-configuration-back-to-overview"
							@click="backToOverview"
						>
							Back to Overview
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton
							v-if="userFullPermissions"
							test-id="manage-sso-configuration-submit"
							@click="handleSubmit(submit)"
						>
							{{ !isEdit ? 'Create' : 'Save' }}
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</SSOConfigurationLayout>
		<SSOMappingModal
			v-if="showMappingModal"
			:lookup-data="lookupData"
			:sso-configuration="ssoConfiguration"
			:user-full-permissions="userFullPermissions"
			:sso-mapping-to-manage="ssoMappingToManage"
			:product-roles="productRoles"
			@close-modal="closeMappingModal"
			@update-sso-mapping="updateSsoMapping"
		/>
		<ConfirmDeleteModal
			v-if="claimToRemove"
			:entity-name="claimToRemove.cassieModuleName"
			entity-type="Claim"
			@close="claimToRemove = null"
			@delete="deleteClaim"
		/>
	</ValidationForm>
</template>

<script>
import { mapGetters } from 'vuex'
import ValidationForm from '../../../../../../shared/components/validation-form.vue'
import SSOConfigurationLayout from './sso-configuration-layout.vue'
import SSOSetupDetailsCard from './sso-setup-details-card.vue'
import SSOConfigurationCard from './sso-configuration-card.vue'
import SSOMappingCard from './sso-mapping-card.vue'
import SSOMappingModal from './sso-mapping-modal.vue'
import ConfirmDeleteModal from '../../../../../../shared/components/confirm-delete-modal.vue'
import PageActionRow from '../../../../../../shared/components/page-action-row.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import { SSO_CONFIGURATION } from '../../../../router/route-names.js'
import { SSO_CONFIGURATION_FULL_PERMISSIONS } from '../../../../../../shared/permissions/admin-portal-permissions.js'
import { getProductRoles } from '../../../../../../shared/utils/api/product-roles.js'
import { postSSOConfiguration, putSSOConfiguration, toggleSSOConfiguration } from '../../../../../../shared/utils/api/sso-configuration.js'
import { showSnackbar } from '../../../../../../shared/state/snackbar.js'
export default {
	components: {
		ValidationForm,
		SSOConfigurationLayout,
		SSOSetupDetailsCard,
		SSOConfigurationCard,
		SSOMappingCard,
		SSOMappingModal,
		ConfirmDeleteModal,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton
	},
	props: {
		ssoConfigurationToManage: Object,
		lookupData: Object
	},
	setup () {
		return {
			SSO_CONFIGURATION
		}
	},
	data () {
		return {
			ssoConfiguration: {},
			showMappingModal: false,
			ssoMappingToManage: null,
			productRoles: [],
			claimToRemove: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		pageTitle () {
			return `SSO Configuration`
		},
		pageCaption () {
			return `Edit and manage SSO Configuration for the Cassie Administration Portal or Customer Service Portal`
		},
		isEdit () {
			return this.ssoConfigurationToManage.createdDate !== 'Not Configured'
		},
		userFullPermissions () {
			return this.productAreaPermission(SSO_CONFIGURATION_FULL_PERMISSIONS)
		}
	},
	async created () {
		this.ssoConfiguration = {
			...this.ssoConfigurationToManage,
			editConnectionDetails: false,
			clientSecret: null,
			mappedClaims: this.mapClaims()
		}
		delete this.ssoConfiguration.clientID
		await this.getProductRoles()
	},
	methods: {
		async getProductRoles () {
			const { data: productRoles } = await getProductRoles(this.ssoConfigurationToManage.productId)
			this.productRoles = productRoles
		},
		updateShowMappingModal (mapping) {
			if (mapping) {
				this.ssoMappingToManage = mapping
			}
			this.showMappingModal = true
		},
		closeMappingModal () {
			this.showMappingModal = false
			this.ssoMappingToManage = null
		},
		updateSsoMapping (mapping) {
			const componentTypeName = this.lookupData.ssoComponentTypes.find(ct => ct.id === mapping[0].componentTypeId).componentTypeName
			const matchingClaim = this.ssoConfiguration.mappedClaims.find(claim => claim.cassieModuleName === componentTypeName)
			if (matchingClaim) {
				matchingClaim.claims = mapping
			} else {
				this.ssoConfiguration.mappedClaims.push({
					cassieModuleName: componentTypeName,
					claims: mapping
				})
			}
			this.closeMappingModal()
		},
		setClaimToRemove (claim) {
			this.claimToRemove = claim
		},
		deleteClaim () {
			this.$delete(this.ssoConfiguration.mappedClaims, this.ssoConfiguration.mappedClaims.indexOf(this.claimToRemove))
			this.claimToRemove = null
		},
		backToOverview () {
			this.$router.push({ name: SSO_CONFIGURATION })
		},
		async submit () {
			const payload = {
				...this.ssoConfiguration,
				claims: this.ssoConfiguration.mappedClaims.flatMap(claim => claim.claims),
				clientId: this.ssoConfiguration.editConnectionDetails ? this.ssoConfiguration.clientId : null,
				clientSecret: this.ssoConfiguration.editConnectionDetails ? this.ssoConfiguration.clientSecret : null
			}
			delete payload.mappedClaims
			delete payload.editConnectionDetails
			delete payload.createdDate
			delete payload.dateCreated
			delete payload.productName
			delete payload.isEnabled
			if (this.isEdit) {
				await putSSOConfiguration(payload, this.ssoConfigurationToManage.id)
			} else {
				await postSSOConfiguration(payload).then(response => {
					const toggleOnConfigPayload = {
						enabled: true,
						productId: this.ssoConfiguration.productId,
						systemConfigurationId: response.data.createdSsoConfigurationId
					}
					toggleSSOConfiguration(toggleOnConfigPayload)
				})
			}
			showSnackbar(`SSO Configuration ${this.isEdit ? 'saved' : 'created'} successfully`)
			this.backToOverview()
		},
		mapClaims () {
			const claims = []
			this.lookupData.ssoComponentTypes.forEach(componentType => {
				const matchedComponentType = this.ssoConfigurationToManage.claims?.filter(claim => claim.componentTypeId === componentType.id)
				if (matchedComponentType?.length > 0) {
					claims.push({
						cassieModuleName: componentType.componentTypeName,
						claims: matchedComponentType ?? []
					})
				}
			})
			return claims
		}
	}
}
</script>
