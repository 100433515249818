<template>
	<SectionCard>
		<template #title>
			SSO Configuration
		</template>
		<template #subtitle>
			Input the Name and Description of the SSO Setup
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="6">
					<TextField
						:value="ssoConfiguration.productName"
						label="Product"
						disabled
						tooltip-text="The Cassie Product being configured for SSO"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						:value.sync="ssoConfiguration.authority"
						:label="selectedProviderAuthorityDisplayName"
						:rules="{ required: true, max: 512 }"
						test-id="sso-configuration-redirect-uri"
						tooltip-text="The URL where an authentication server sends the user after they've successfully logged in"
						:disabled="!userFullPermissions"
						@input="updateSsoConfiguration('authority', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<TextField
						:value.sync="ssoConfiguration.identityClaimTypeName"
						label="Identity Claim Type Name *"
						rules="required"
						test-id="sso-configuration-identity-claim-type-name"
						tooltip-text="The specific attribute about your user that an identity provider sends to Cassie during the SSO process. e.g. Email Address. The Cassie Admin Portal must be a claim that contains the Email Address. The Cassie Customer Service Portal must be a claim that contains the Username of the User"
						:disabled="!userFullPermissions"
						@input="updateSsoConfiguration('identityClaimTypeName', $event)"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						:value.sync="ssoConfiguration.remoteLogoutEndpointUrl"
						:label="selectedProviderRemoteLogoutEndpointUrlDisplayName"
						:rules="{ required: true, max: 512 }"
						test-id="sso-configuration-sign-out-redirect-uri"
						tooltip-text="The URL where a user is redirected after they have successfully signed out of Cassie"
						:disabled="!userFullPermissions"
						@input="updateSsoConfiguration('remoteLogoutEndpointUrl', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<TextField
						:value.sync="ssoConfiguration.tenantGuid"
						label="Configuration GUID"
						:rules="{required: true, noUppercase: true}"
						test-id="sso-configuration-organization-id"
						tooltip-text="This is a unique identifier (GUID) assigned to each Cassie SSO configuration.  This helps Cassie distinguish one SSO configuration from another and must be unique"
						:disabled="!userFullPermissions"
						@input="updateSsoConfiguration('tenantGuid', $event)"
					/>
				</v-col>
				<v-col cols="5">
					<PrimaryActionButton
						v-if="userFullPermissions"
						small-width
						@click="generateGuid"
					>
						Generate
					</PrimaryActionButton>
				</v-col>
			</v-row>
			<validation-provider
				#default="{ valid }"
				:rules="scopeFieldRules"
			>
				<v-row dense>
					<v-col cols="6">
						<TextField
							v-model="scope"
							label="Scope"
							test-id="sso-configuration-scope"
							tooltip-text="This defines permissions or access levels that Cassie can request from the SSO provider during the authentication process"
							:rules="scopeFieldRules"
							:disabled="!userFullPermissions"
						/>
					</v-col>
					<v-col cols="5">
						<PrimaryActionButton
							v-if="userFullPermissions"
							small-width
							:disabled="!scope"
							@click="addScope(valid)"
						>
							Add
						</PrimaryActionButton>
					</v-col>
				</v-row>
			</validation-provider>
			<v-row
				class="mt-2"
				dense
			>
				<v-col cols="12">
					<DataTable
						:headers="tableHeaders"
						:items="scopeItems"
						hide-default-footer
					>
						<template #item.action="{ item, index }">
							<IconButton
								v-if="index !== 0 && userFullPermissions"
								class="mr-3"
								tooltip-text="Remove Scope"
								:test-id="`remove-scope-${item.scopeName}`"
								@click="removeScope(item.scopeName)"
							>
								mdi-trash-can
							</IconButton>
						</template>
					</DataTable>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<Toggle
						label="Edit Connection Details"
						:value.sync="ssoConfiguration.editConnectionDetails"
						tooltip-text="The Client ID is used by the SSO Provider to identify Cassie during the authentication process"
						test-id="sso-configuration-edit-connection-details"
						:disabled="!userFullPermissions"
						@update:value="updateSsoConfiguration('editConnectionDetails', $event)"
					/>
				</v-col>
			</v-row>
			<v-row
				v-if="ssoConfiguration.editConnectionDetails"
				dense
			>
				<v-col cols="6">
					<TextField
						:value.sync="ssoConfiguration.clientId"
						label="Client ID *"
						rules="required"
						test-id="sso-configuration-client-id"
						tooltip-text="The Client ID is used by the SSO Provider to identify Cassie during the authentication process"
						:disabled="!userFullPermissions"
						@input="updateSsoConfiguration('clientId', $event)"
					/>
				</v-col>
				<v-col cols="6">
					<TextField
						:value.sync="ssoConfiguration.clientSecret"
						label="Client Secret *"
						rules="required"
						test-id="sso-configuration-client-secret"
						tooltip-text="This is a confidential string of characters shared between Cassie and the SSO provider"
						:disabled="!userFullPermissions"
						@input="updateSsoConfiguration('clientSecret', $event)"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Toggle from '../../../../../../shared/components/toggle.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import { uuid } from '../../../../../../shared/utils/uuid.js'
export default {
	components: {
		SectionCard,
		TextField,
		Toggle,
		DataTable,
		PrimaryActionButton,
		IconButton
	},
	props: {
		ssoConfiguration: Object,
		lookupData: Object,
		userFullPermissions: Boolean
	},
	data () {
		return {
			editConnectionDetails: false,
			scope: null
		}
	},
	computed: {
		selectedProviderRemoteLogoutEndpointUrlDisplayName () {
			const ssoProvider = this.lookupData.ssoProviders.find(provider => provider.id === this.ssoConfiguration.ssoProviderId)
			return `${ssoProvider.remoteLogoutEndpointUrlDisplayName} *`
		},
		selectedProviderAuthorityDisplayName () {
			const ssoProvider = this.lookupData.ssoProviders.find(provider => provider.id === this.ssoConfiguration.ssoProviderId)
			return `${ssoProvider.authorityDisplayName} *`
		},
		tableHeaders () {
			return [
				{
					text: 'Scope Name',
					value: 'scopeName'
				},
				{
					text: 'Action',
					value: 'action',
					align: 'right',
					sortable: false
				}
			]
		},
		scopeItems () {
			const mappedScopes = this.ssoConfiguration?.scopes?.split(',').map(scope => ({
				scopeName: scope
			})).filter(scope => scope.scopeName !== '') ?? []
			return [
				{
					scopeName: 'openId'
				},
				...mappedScopes
			]
		},
		scopeFieldRules () {
			return {
				duplicateValues: { value: this.scopeItems.map(scope => scope.scopeName) },
				forbiddenChars: { value: [' ', ','] }
			}
		}
	},
	methods: {
		updateEditConnectionDetails (value) {
			this.editConnectionDetails = value
		},
		updateSsoConfiguration (property, value) {
			this.$emit('update:ssoConfiguration', {
				...this.ssoConfiguration,
				[property]: value
			})
		},
		addScope (valid) {
			if (!valid) return
			const scopes = this.ssoConfiguration.scopes ? this.ssoConfiguration.scopes.split(',') : []
			scopes.push(this.scope)
			this.updateSsoConfiguration('scopes', scopes.join(','))
			this.scope = null
		},
		removeScope (scopeName) {
			const scopes = this.ssoConfiguration.scopes.split(',')
			const updatedScopes = scopes.filter(scope => scope !== scopeName)
			this.updateSsoConfiguration('scopes', updatedScopes.join(','))
		},
		generateGuid () {
			this.updateSsoConfiguration('tenantGuid', uuid())
		}
	}
}
</script>
