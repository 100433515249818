<template>
	<ViewLayout>
		<template #header-title>
			{{ title }}
		</template>
		<template
			v-if="caption"
			#header-caption
		>
			{{ caption }}
		</template>
		<template #header-after>
			<slot name="header-after" />
		</template>
		<template #content>
			<slot name="content" />
		</template>
		<template #footer>
			<slot name="footer" />
		</template>
	</ViewLayout>
</template>
<script>
import ViewLayout from '../../../../../../shared/layouts/view-layout.vue'
export default {
	components: { ViewLayout },
	props: {
		title: {
			type: String,
			required: true
		},
		caption: String
	}
}
</script>
