import { format } from 'date-fns'
import { configApi } from '../http-client.js'
import { DATE_FORMAT } from '../date-formatting.js'

export const getSSOConfiguration = () => configApi.get('/api/SSOConfiguration').then(({ data }) => ({
	data: {
		ssoConfigurations: data.ssoConfigurations.map(ssoConfiguration => ({
			...ssoConfiguration,
			productName: ssoConfiguration.productId === 1 ? 'Administration Portal' : 'Customer Service Portal',
			createdDate: ssoConfiguration.dateCreated ? format(new Date(ssoConfiguration.dateCreated), DATE_FORMAT) : 'Not Configured'
		})),
		lookupData: data.lookupData
	}
}))

export const postSSOConfiguration = ssoConfiguration => configApi.post('/api/SSOConfiguration', ssoConfiguration)

export const putSSOConfiguration = (ssoConfiguration, id) => configApi.put(`/api/SSOConfiguration/${id}`, ssoConfiguration)

export const toggleSSOConfiguration = configuration => configApi.put('api/SSOConfiguration/ToggleSsoConfiguration', configuration)
