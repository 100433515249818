<template>
	<SectionCard>
		<template #title>
			SSO Mapping
		</template>
		<template #subtitle>
			Setup claims within Cassie to set User Roles & Brands upon login from an SSO Provider
		</template>
		<template #title-action>
			<TextButton
				v-if="userFullPermissions && ssoConfiguration.mappedClaims.length !== 2"
				@click="$emit('show-mapping-modal')"
			>
				<v-icon>
					mdi-plus
				</v-icon>
				Create SSO Mapping
			</TextButton>
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="12">
					<DataTable
						:headers="tableHeaders"
						:items="ssoConfiguration.mappedClaims"
						hide-default-footer
						@click:row="manageExistingClaims"
					>
						<template #item.componentTypeExternalName="{ item }">
							{{ getFirstClaimGroup(item) }}
						</template>
						<template #item.action="{ item }">
							<IconButton @click="manageExistingClaims(item)">
								{{ userFullPermissions ? 'mdi-pencil' : 'mdi-eye' }}
							</IconButton>
							<IconButton
								v-if="userFullPermissions"
								@click.stop.prevent="deleteClaim(item)"
							>
								mdi-trash-can
							</IconButton>
						</template>
					</DataTable>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextButton from '../../../../../../shared/components/text-button.vue'
import IconButton from '../../../../../../shared/components/icon-button.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'
export default {
	components: {
		SectionCard,
		TextButton,
		IconButton,
		DataTable
	},
	props: {
		ssoConfiguration: Object,
		lookupData: Object,
		userFullPermissions: Boolean
	},
	data () {
		return {
			showMappingModal: false
		}
	},
	computed: {
		selectedProvider () {
			return this.lookupData.ssoProviders.find(provider => provider.id === this.ssoConfiguration.ssoProviderId)
		},
		tableHeaders () {
			return [
				{
					text: 'Cassie Module',
					value: 'cassieModuleName'
				},
				{
					text: this.selectedProvider.claimMappingDisplayName,
					value: 'componentTypeExternalName'
				},
				{
					text: 'Action',
					value: 'action',
					align: 'right',
					sortable: false
				}
			]
		}
	},
	methods: {
		manageExistingClaims (item) {
			this.$emit('show-mapping-modal', item.claims)
		},
		getFirstClaimGroup (item) {
			return item.claims[0]?.componentTypeExternalName
		},
		deleteClaim (item) {
			this.$emit('delete-claim', item)
		}
	}
}
</script>
