<template>
	<SectionCard>
		<template #title>
			SSO Setup Details
		</template>
		<template #subtitle>
			Input the Name and Description of the SSO Setup
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="6">
					<TextField
						:value.sync="ssoConfiguration.name"
						label="Name *"
						rules="required"
						test-id="sso-setup-name"
						:disabled="!userFullPermissions"
						@input="updateSsoConfiguration('name', $event)"
					/>
				</v-col>
				<v-col cols="6">
					<Dropdown
						:value.sync="ssoConfiguration.ssoProviderId"
						label="SSO Provider *"
						:items="ssoProviderItems"
						rules="required"
						test-id="sso-provider"
						:disabled="!userFullPermissions"
						@input="updateSsoConfiguration('ssoProviderId', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12">
					<TextArea
						:value.sync="ssoConfiguration.description"
						label="Description *"
						no-resize
						test-id="sso-setup-description"
						:disabled="!userFullPermissions"
						@input="updateSsoConfiguration('description', $event)"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../shared/components/dropdown.vue'
import TextArea from '../../../../../../shared/components/textarea.vue'

export default {
	components: {
		SectionCard,
		TextField,
		Dropdown,
		TextArea
	},
	props: {
		ssoConfiguration: Object,
		userFullPermissions: Boolean,
		lookupData: Object
	},
	computed: {
		ssoProviders () {
			return this.lookupData.ssoProviders
		},
		ssoProviderItems () {
			return this.ssoProviders.map(ssoProvider => ({
				text: ssoProvider.ssoProviderName,
				value: ssoProvider.id
			}))
		}
	},
	methods: {
		updateSsoConfiguration (property, value) {
			this.$emit('update:ssoConfiguration', {
				...this.ssoConfiguration,
				[property]: value
			})
		}
	}
}
</script>
